<template>
  <div style="flex:1;display:flex;">
    <div
      style="
        flex:1;
        height: auto;
        background-color: #fff;
        margin-top: 10px;
        padding:30px 0;
        border-box:box-sizing;
      "
    >
      <div class="title">
        <div>刊大师服务条款</div>
      </div>
      <div
        class="agreement"
        v-for="(item, index) in agreementList"
        :key="index"
      >
        <div class="agreement-title">{{ item.agreement_name }}</div>
        <div class="agreement-content" v-html="item.agreement_content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { agreementByType } from "@/API/homepage/agreement.js";
export default {
  components: {},
  data() {
    return {
      agreementList:[],
    };
  },
  mounted(){
    this.agreementByType();
  },
  methods: {
    agreementByType() {
      agreementByType(4).then((res) => {
        this.agreementList = res.data.data;
      });
    },
  },
};
</script>

<style>
.nav {
  flex: 1;
  width: 100%;
  height: auto;
}
.hander {
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
}

.hander img {
  margin-left: 320px;
}

.searchClass {
  width: 380px;
  height: 40px;
  margin-left: 200px;
  border: 1px solid #ff0000;
  border-radius: 20px;
}
.searchClass .el-input-group__prepend {
  border: none;
  background-color: transparent;
  padding: 0 10px 0 30px;
}
.searchClass .el-input-group__append {
  border: none;
  background-color: transparent;
}
.searchClass .el-input__inner {
  height: 36px;
  line-height: 36px;
  border: none;
  background-color: transparent;
}
.searchClass .el-icon-search {
  font-size: 16px;
}
.searchClass .centerClass {
  height: 100%;
  line-height: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}
.searchClass .line {
  width: 1px;
  height: 26px;
  background-color: #c5c5c5;
  margin-left: 14px;
}

.sousu {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.hander-img {
  position: relative;
  left: 390px;
}
.title {
  width: 100%;
  height: 50px;

  line-height: 50px;
  margin: 10px auto;
}
.title div {
  width: 180px;
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #141414;
  line-height: 30px;
  margin: 10px auto;
}
.agreement{
  width: 80%;
  margin: 0 auto;
}
.agreement-content{
  margin-top: 16px;
}
</style>
